import React, { useState } from "react";
import axios from "axios";
import { Layout, Col, Row, Form, Input, Button, Divider, message } from "antd";
import { Link } from "gatsby";
import { LockOutlined } from "@ant-design/icons";
import { getLocalFormValues, navigate } from "../utils";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

export default function DefineUserAccess() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const initFormValues = getLocalFormValues();
  if (!initFormValues) {
    /* clear of the localStorage + direct access to URL => go home */
    navigate("/");
    return null;
  }

  function handleSubmit({ password }) {
    const { request_id: requestId } = initFormValues;
    setIsLoading(true);
    axios
      .post(process.env.GATSBY_API_URL + "/define-rider-access", {
        requestId,
        password,
      })
      .then(() => {
        navigate("/mon-espace-rider");
      })
      .catch((e) => {
        message.error("Erreur lors de la communication : " + e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Création de mon Espace Rider</h2>
            <p>
              Définissez un mot de passe afin de pouvoir accèder à votre espace
              Rider
            </p>
            <Row>
              <Col
                lg={{ span: 10, offset: 7 }}
                xs={24}
                style={{ textAlign: "center" }}
              >
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  className="login-form"
                >
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Merci de saisir votre mot de passe",
                      },
                      () => ({
                        validator(_, value) {
                          const isNonWhiteSpace = /^\S*$/;
                          if (!isNonWhiteSpace.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Le mot de passe ne peut pas contenir d'espace"
                              )
                            );
                          }
                          const isContainsNumber = /^(?=.*[0-9]).*$/;
                          if (!isContainsNumber.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Le mot de passe doit contenir un chiffre"
                              )
                            );
                          }
                          const isValidLength = /^.{8,16}$/;
                          if (!isValidLength.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Le mot de passe doit être entre 8 et 16 caractères"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                    hasFeedback
                  >
                    <Input
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="Mot de passe"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password2"
                    rules={[
                      {
                        required: true,
                        message: "Merci de confirmer votre mot de passe",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "Les 2 mots de passe ne correspondent pas"
                            )
                          );
                        },
                      }),
                    ]}
                    dependencies={["password"]}
                  >
                    <Input
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="Confirmation mot de passe"
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ marginTop: "2em" }}
                    loading={isLoading}
                  >
                    Créer mon Espace Rider
                  </Button>
                  <br />
                  <br />
                  <Divider />
                  Pas de compte ? &nbsp;
                  <Link to="/">
                    <Button>Je m'assure</Button>
                  </Link>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
